<template>
    <div class="top-bar">
        <s-header :name="$t('LAN.myservice')"></s-header>
    </div>
    <div class="service"   style="height: 98vh;">
        <div class="service-content">
            <div class="head"> 
                <span>{{$t('LAN.myservice_count')}}</span>
                <span :style="{'color': handlerCount > 0 ? '#ff5353' : '#6e87ea'}" > {{ handlerCount }}</span>
                <span>{{$t('LAN.myservice_count2')}}</span> 
            </div>

            <van-list
                :finished="true"
                :finished-text="$t('LAN.nomore')">
                <ul class="list">
                    <li v-for="item in feedbackList" :key="item" @click="goTo(`/myHelper/feedback/detail/${item.id}`)">
                        <div class="top">
                            <em class="icon-status">{{$t('LAN.feedback_state')}}</em>
                            <span  :style="{'color': statusStr[item.status].color}"> {{ statusStr[item.status].label }} </span>
                        </div>
                        <div class="item">
                            <em>{{$t('LAN.feedback_type')}}</em>
                            <span :class="$L()">{{$t(menus[item.type].label)}}</span>
                        </div>
                        <div class="item">
                            <em>{{$t('LAN.create_time')}}</em>
                            <span :class="$L()"> {{ item.createTime }} </span>
                        </div>
                    </li>
                </ul>
            </van-list>

        </div>
    </div>
</template>

<script>
import { reactive, onMounted, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import sHeader from '@/components/Header'
import { Toast } from 'vant'
import { requestMyService } from "@/service/feedback"
import { feedbackMenus } from '@/configs/feedback'
import { useI18n } from 'vue-i18n'

export default {
    setup() {
        const store = useStore()
        const router = useRouter()
        const { t } = useI18n()

        const state = reactive({
            statusStr:{
                0: {
                    label: t('LAN.processing'),
                    color: "#ff5353",
                },
                1: {
                    label: t('LAN.replied'),
                    color: "#5ac845",
                },
                2: {
                    label: t('LAN.completed'),
                    color: "#6e87ea",
                },
            },
            menus: feedbackMenus,
            feedbackList:[],
            handlerCount:0,
        });

        onMounted(async () => {
            Toast.loading(t('LAN.loading'));

            const result = await requestMyService({
                gameId:store.getters.appId
            });
            state.feedbackList = result.feedbackList
            for (let i = 0; i < state.feedbackList.length; i++) {
                let item = state.feedbackList[i];
                if (item.status == 0) {
                    state.handlerCount ++;
                }
            }
            Toast.clear()
        });


        const goTo = (url) => {
            router.push({ path: url })
        };

        return {
            ...toRefs(state),
            goTo,
        }
    },
    components: {
        sHeader
    },
}
</script>


<style lang="less" scoped>
@import '../../../style/mixin';

.service {
    min-height: 100%;
    background-color: #fff;
    box-sizing: border-box;
    text-align: left;
    .service-content {
        padding: 23px;
        .head {
            padding: 0 14px;
            height: 50px;
            background-color: #fff;
            border-radius: 7px;
            border: 1px solid #eee;
            font-size: 16px;
            color: #b1b1b1;
            line-height: 50px;
            margin-bottom: 20px;
        }

        .list li {
            padding: 0 20px;
            height: 180px;
            background-color: #fff;
            border-radius: 1.333vw;
            border: 1px solid #eee;
            margin-bottom: 20px;
            .top {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 60px;
                border-bottom: 1px solid #eee;
                margin-bottom: 15px;
                .icon-status {
                    position: relative;
                    font-style: normal;
                    font-size: 19px;
                    color: #333;
                }
                span {
                    flex: 1;
                    font-size: 15px;
                    color: #ff5353;
                    text-align: right;
                }
                //阿拉伯样式
                .L{
                    text-align: left;
                }
            }
        }
        .list {
            .item {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 40px;
                em {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 15px;
                    color: #333;
                }
                span {
                    flex: 1;
                    font-size: 15px;
                    color: #b1b1b1;
                    text-align: right;
                }
                //阿拉伯样式
                .L{
                    text-align: left;
                }
            }
        }
    }
}
</style>